import { required, minLength, not, sameAs } from 'vuelidate/lib/validators'

export default {
  computed: {
    isFormValid() {
      return !this.$v.params.$anyError
    }
  },

  validations: {
    params: {
      OldPassword: {
        required
      },

      NewPassword: {
        required,
        minLength: minLength(6),
        notSameOldPassword: not(sameAs('OldPassword'))
      },

      ConfirmNewPassword: {
        required,
        samePassword: sameAs('NewPassword')
      }
    }
  }
}
