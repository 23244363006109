<template>
  <div>
    <h2 class="headline--lg text-bold mb-32 text-center">Change Password</h2>

    <form @submit.prevent="onChangePassword">
      <div class="form-group">
        <label class="form-label">Old Password</label>
        <input
          type="password"
          :class="['form-control', { 'form-control--error': $v.params.OldPassword.$anyError }]"
          v-model="$v.params.OldPassword.$model"
        />
        <p v-if="$v.params.OldPassword.$anyError" class="form-error-text">
          <span v-if="!$v.params.OldPassword.required">OldPassword can't be blank</span>
        </p>
      </div>

      <div class="form-group">
        <label class="form-label">New Password</label>
        <input
          type="password"
          :class="['form-control', { 'form-control--error': $v.params.NewPassword.$anyError }]"
          v-model="$v.params.NewPassword.$model"
        />
        <p v-if="$v.params.NewPassword.$anyError" class="form-error-text">
          <span v-if="!$v.params.NewPassword.required">NewPassword can't be blank</span>
          <span v-if="!$v.params.NewPassword.minLength">Password is too short (minimum is 6 characters)</span>
          <span v-if="!$v.params.NewPassword.notSameOldPassword">New password cannot be same as old one</span>
        </p>
      </div>

      <div class="form-group">
        <label class="form-label">Confirm New Password</label>
        <input
          type="password"
          :class="['form-control', { 'form-control--error': $v.params.ConfirmNewPassword.$anyError }]"
          v-model="$v.params.ConfirmNewPassword.$model"
        />
        <p v-if="$v.params.ConfirmNewPassword.$anyError" class="form-error-text">
          <span v-if="!$v.params.ConfirmNewPassword.samePassword">Password confirm must be identical</span>
        </p>
      </div>

      <div class="form-group">
        <button class="btn btn--primary btn--full">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import changePasswordFormValidator from '@/validations/change-password-form'

export default {
  data() {
    return {
      params: {
        OldPassword: '',
        NewPassword: '',
        ConfirmNewPassword: ''
      }
    }
  },

  mixins: [changePasswordFormValidator],

  methods: {
    onChangePassword() {
      this.$v.$touch()
      if (this.isFormValid) this.$emit('on-change-password', this.params)
    }
  }
}
</script>
